exports.components = {
  "component---src-components-templates-author-news-tsx": () => import("./../../../src/components/templates/AuthorNews.tsx" /* webpackChunkName: "component---src-components-templates-author-news-tsx" */),
  "component---src-components-templates-page-tsx": () => import("./../../../src/components/templates/Page.tsx" /* webpackChunkName: "component---src-components-templates-page-tsx" */),
  "component---src-components-templates-post-tsx": () => import("./../../../src/components/templates/Post.tsx" /* webpackChunkName: "component---src-components-templates-post-tsx" */),
  "component---src-components-templates-project-tsx": () => import("./../../../src/components/templates/Project.tsx" /* webpackChunkName: "component---src-components-templates-project-tsx" */),
  "component---src-components-templates-team-member-tsx": () => import("./../../../src/components/templates/TeamMember.tsx" /* webpackChunkName: "component---src-components-templates-team-member-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-404-tsx": () => import("./../../../src/pages/en/404.tsx" /* webpackChunkName: "component---src-pages-en-404-tsx" */)
}

