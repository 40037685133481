import "./src/base/styles/main.css";

import React from "react";
import { MatchMediaProvider, LanguageProvider } from "@/context";
import { combineComponents } from "@/base/helpers/combineComponents";

const MainLayoutProvider = combineComponents(
  MatchMediaProvider,
  LanguageProvider
);


const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

const scrollToElement = (hash) => {
  const element = document.querySelector(hash);
  if (element) {
    element.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
  } else {
    scrollToTop();
  }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.search.includes("filters=")) return false;
  return true;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation) return;

  const isNewPath = location.pathname !== prevLocation.pathname;
  const hasHash = !!location.hash;

  setTimeout(() => {
    if (isNewPath) {
      hasHash ? scrollToElement(location.hash) : scrollToTop();
    }
    // If same route, do nothing (let browser handle default or smooth scroll)
  }, 0);
};


export const wrapRootElement = ({ element }) => {
  return <MainLayoutProvider>{element}</MainLayoutProvider>;
};

// GTM
function initHubspot() {
  if (window.isHubspotLoaded) {
    return;
  }

  window.isHubspotLoaded = true;

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = "//js.hsforms.net/forms/shell.js";

  document.body.appendChild(script);
}

export const onClientEntry = () => {
  document.onreadystatechange = () => {
    if (document.readyState !== "loading") {
      // The Hubspot form script should only be loaded on the /apply page, as it's only used for the apply page
      if (window.location.pathname.includes('/apply')) {
        initHubspot();
      } else {
        // If the page is not /apply, we can delay the Hubspot form script to reduce the main thread stress
        setTimeout(initHubspot, 4000);
      }
    }
  };
};
